import React, {useCallback, useEffect, useState} from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import {getCookie} from "cookies-next";
import * as Sentry from "@sentry/nextjs";

export default function DialogDelete ({productDeleteUUID,toggleDeleteMultiple,
                                          deleteProductIDMultiple,productVersionDeleteUUID,
                                          onDeleteReloadMembers,
                                      toggleDelete,reloadProducts,userDeleteUUID,type,triggerWarning,
                                          changeLoadingShow}) {

    const [loadingDelete, setLoadingDelete] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const [deleteTransitionShow, setDeleteTransitionShow] = useState(false);

    const changeDeleteShow = (variable) => {

        if(variable === true){

            setDeleteShow(variable);
            setTimeout(function() {
                setDeleteTransitionShow(true);
            },1);




        } else{


            setDeleteTransitionShow(false);
            setTimeout(function() {
                setDeleteShow(variable);
            },500);



        }

    };
    const deleteProductCheck = (productID) => {
        console.log("deltePRoduct check ",productID,productDeleteUUID);
        // setTrigger(productID);
       // changeProductDeleteUUID(productID);

        changeDeleteShow(true);
    };


    const [deleteState, setDeleteState] = useState(1);

    const [deleteParent, setDeleteParent] = useState("");

    const deleteProduct = (check) => {
        changeLoadingShow(true);
        console.log("DELETEING PRODUCsdasdasdsaT ", productDeleteUUID);
        setLoadingDelete(true);

var check2 = "true";

if(check === false){
    check2 = "false";
}

        var newURI = process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"products";
var arrayDel = [productDeleteUUID];
        if(productVersionDeleteUUID){
            newURI = process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"products/"+productDeleteUUID + "/versions";
            arrayDel = [productVersionDeleteUUID];
        }

console.log(newURI);

        fetch(newURI+"?check="+check2, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
            body: JSON.stringify(arrayDel),
        })
            .then(response => response.json())
            .then(function(data){
                console.log("DATA API DELETE", data);
                //  clientLogo
                if(data.message !== undefined){
                    Sentry.captureException("Dialogdelete deleteProduct products/"+productDeleteUUID+": "+ data.message + " : " + getCookie("userMail"));
                }
                closeDeleteShow();
                if(data.message === undefined){
                    // changeDeleteShow(false);
                 //   handleClick();
                    reloadProducts();
                    console.log("DELETING PRODUCT200");


                } else {
               // /    closeDeleteShow();
                  //  triggerWarning(data.message);
                    console.log(data);
                    changeLoadingShow(false);
                    setDeleteParent(data.data["0"]["relations"]["0"]["affected"]["0"]["parent"]["name"]);

                    setDeleteState(2);
                }

            });

    };

    const deleteProductMultiple = () => {
        changeLoadingShow(true);
        console.log("DELETEING prdouctmutliplte ", deleteProductIDMultiple);
        setLoadingDelete(true);
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"products", {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
            body: JSON.stringify(    [deleteProductIDMultiple]),
        })
            .then(response => response.json())
            .then(function(data){
                console.log("DATA API DELETE", data);
                //  clientLogo
                if(data.message !== undefined){
                    Sentry.captureException("Dialogdelete deleteProductMultiple products/"+productDeleteUUID+": "+ data.message + " : " + getCookie("userMail"));
                }
                closeDeleteShow();
                if(data.message === undefined){
                    // changeDeleteShow(false);
                    //   handleClick();
                    reloadProducts();
                    console.log("DELETING PRODUCT200");

                    var countReady = deleteProductIDMultiple.length-1;
                    deleteProductIDMultiple.map((value, index) => {
                     console.log("VAlue", value, countReady);

                        closeDeleteShow();
                        changeLoadingShow(false);

                    })



                } else {
                    closeDeleteShow();
                    triggerWarning(data.message);
                }

            });

    };

    const deleteUser = (type) => {

        if(type === "team"){
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"teams/current/members/"+userDeleteUUID, {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function(data){
                    console.log("DATA API DELETE", data);
                    if(data.message !== undefined){
                        Sentry.captureException("deleteUserTeam teams/current/members/"+userDeleteUUID+": "+ data.message + " : " + getCookie("userMail"));
                    }
                    if(data.message === undefined){

                        closeDeleteShow();

                        onDeleteReloadMembers();
                    } else {
                        closeDeleteShow();
                        triggerWarning(data.message);
                    }

                });
        } else {
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"user/current", {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function(data){
                    console.log("DATA API DELETE", data);
                    if(data.message !== undefined){
                        Sentry.captureException("deleteUser current" + getCookie("userMail"));
                    }
                    if(data.message === undefined){

                        closeDeleteShow();

                    } else {
                        closeDeleteShow();
                        triggerWarning(data.message);
                    }

                });
        }


    };

    let closeDeleteShow = () => {
console.log("CLOSING SHOW");
        changeDeleteShow(false);

        setTimeout(function() {
            setLoadingDelete(false);
        },300);
    };
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const toggleDeleteInside = useCallback((): void => {
        if(showDeleteDialog){
            changeDeleteShow(false);
        }else{
            setDeleteMultiple(false);
            changeDeleteShow(true);
        }


    }, []);
    useEffect(() => {

        toggleDelete && toggleDelete(toggleDeleteInside);
    }, [toggleDelete, toggleDeleteInside]);

    const [deleteMultiple, setDeleteMultiple] = useState(false);

    const toggleDeleteInsideMultiple = useCallback((): void => {
        if(showDeleteDialog){
            changeDeleteShow(false);
        }else{
            setDeleteMultiple(true);
            changeDeleteShow(true);
        }


    }, []);
    useEffect(() => {

        toggleDeleteMultiple && toggleDeleteMultiple(toggleDeleteInsideMultiple);
    }, [toggleDeleteMultiple, toggleDeleteInsideMultiple]);

    const closeModalOutsideClick = (e) => {
        console.log(e.target, e.target.getAttribute("data-closemodal"));

        if( e.target.getAttribute("data-closemodal") === "true"){
            changeDeleteShow(false);
        }
    }


    return (
        <>

            <ModalsWrapper className={`deleteCheck  ${deleteTransitionShow ? 'showTransition' : 'hideTransition'} ${!deleteShow ? 'hiddeComplete' : ''}`}
                           data-closemodal={true}
                           onClick={(e)=> closeModalOutsideClick(e)}
            >


                <ModalWindow
                    className={"center modal_dialog_small"}
                    id={'deleteProductDialog'} style={{ minHeight: 'unset',     height: "170px" }}>
                    <div className={'modal-header'}>
                        <h4>Delete</h4>

                    </div>

                    {deleteState === 1 ?
                        <div className={'modal-content shareProduct'} style={{ display: "block" }}>
                            <span>Are you sure you wanna delete ?</span>

                            <div className={"modal-buttons"}>

                                <GlobalButtonFinal
                                                   className={"borderBlue btn-circle"}
                                                   onClick={() => changeDeleteShow(false)}>
                                    Cancel
                                </GlobalButtonFinal>

                                <GlobalButtonFinal
                                                   className={"backgroundRed btn-circle"} data-uuid={productDeleteUUID}
                                                   onClick={type ? () => deleteUser(type) : (deleteMultiple ? () => deleteProductMultiple() : () => deleteProduct(false))}>
                                    Delete
                                </GlobalButtonFinal>

                            </div>


                        </div> : null
                    }


                    {deleteState === 2 ?
                        <div className={'modal-content shareProduct'} style={{ display: "block" }}>
                            <span>Deleting by this product you will affect an {deleteParent}, are you sure you want to delete ?</span>

                            <div className={"modal-buttons"}>

                                <GlobalButtonFinal
                                                   className={"borderBlue btn-circle"}
                                                   onClick={() => changeDeleteShow(false)}>
                                    Cancel
                                </GlobalButtonFinal>

                                <GlobalButtonFinal
                                                   className={"backgroundRed btn-circle"} data-uuid={productDeleteUUID}
                                                   onClick={type ? () => deleteUser(type) : (deleteMultiple ? () => deleteProductMultiple() : () => deleteProduct(false))}>
                                    Delete anyway
                                </GlobalButtonFinal>

                            </div>


                        </div> : null
                    }


                </ModalWindow>


            </ModalsWrapper>


        </>
    )
}